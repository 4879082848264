// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgShare = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.833 3.263v2.984h5.004c.919 0 1.663.746 1.663 1.662v10.01c0 .918-.744 1.662-1.663 1.662H4.163A1.663 1.663 0 0 1 2.5 17.919V7.909c0-.918.744-1.662 1.663-1.662h5.004V3.26l-.942.942a.833.833 0 0 1-1.179-1.179L9.416.655a.82.82 0 0 1 .58-.24l.007.002a.82.82 0 0 1 .58.238l2.368 2.368a.833.833 0 0 1-1.178 1.179zM9.167 7.914h-5v10h11.666v-10h-5v3.335a.833.833 0 0 1-1.667 0zm-2.5-1.667v1.667h6.666V6.247zm2.5 0h1.666v1.667H9.167z"
    />
  </svg>
);
export const ShareIcon = forwardRef(SvgShare);
