// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgArrowDown = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.168 4.168v9.309L5.1 9.41a.84.84 0 0 0-1.183 0 .83.83 0 0 0 0 1.175l5.491 5.492a.83.83 0 0 0 1.175 0l5.5-5.484a.83.83 0 1 0-1.175-1.175l-4.075 4.059V4.168A.836.836 0 0 0 10 3.335a.836.836 0 0 0-.833.833Z"
    />
  </svg>
);
export const ArrowDownIcon = forwardRef(SvgArrowDown);
