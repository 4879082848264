// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgArrowUp = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.832 15.832V6.523L14.9 10.59a.84.84 0 0 0 1.183 0 .83.83 0 0 0 0-1.175l-5.491-5.492a.83.83 0 0 0-1.175 0l-5.5 5.484a.83.83 0 1 0 1.175 1.175l4.075-4.059v9.309c0 .458.375.833.833.833a.836.836 0 0 0 .833-.833Z"
    />
  </svg>
);
export const ArrowUpIcon = forwardRef(SvgArrowUp);
